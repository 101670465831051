body {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px;
  padding: 0px;
  font-family: 'Marsden Slim', sans-serif;
  font-weight: 700;
  font-size: 14px;
}

@font-face {
  font-family: 'Marsden Slim';
  src: url('@/assets/fonts/MarsdenSlim-Regular.woff2') format('woff2'),
    url('@/assets/fonts/MarsdenSlim-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Marsden Slim';
  src: url('@/assets/fonts/MarsdenSlim-SemiBold.woff2') format('woff2'),
    url('@/assets/fonts/MarsdenSlim-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Marsden Slim';
  src: url('@/assets/fonts/MarsdenSlim-Bold.woff2') format('woff2'),
    url('@/assets/fonts/MarsdenSlim-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Marsden Compressed';
  src: url('@/assets/fonts/MarsdenCompressed-Black.woff2') format('woff2'),
    url('@/assets/fonts/MarsdenCompressed-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Marsden Compressed';
  src: url('@/assets/fonts/MarsdenCompressed-Medium.woff2') format('woff2'),
    url('@/assets/fonts/MarsdenCompressed-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'General Sans Variable';
  src: url('@/assets/fonts/GeneralSans-Variable.woff2') format('woff2'),
  url('@/assets/fonts/GeneralSans-Variable.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
